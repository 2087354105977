import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { VisitorDto } from '../dtos/visitor-dto.model';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  // sendMail(visitor: VisitorDto): Observable<any> {
  //   return this.http.post<any>(environment.baseUrl + '/send', visitor );
  // }
}
