import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eu-information',
  templateUrl: './eu-information.component.html',
  styleUrls: ['./eu-information.component.css']
})
export class EuInformationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
