import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-dialog-done',
  templateUrl: './dialog-done.component.html',
  styleUrls: ['./dialog-done.component.css']
})
export class DialogDoneComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogDoneComponent>) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
    // restart page
    window.location.reload();
  }
}
