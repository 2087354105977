import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainPageComponent } from './main-page/main-page.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterbarComponent } from './footerbar/footerbar.component';
import { LogoComponent } from './logo/logo.component';
import { RoomComponent } from './room/room.component';
import { PriceListComponent } from './price-list/price-list.component';
import { ReservationComponent } from './reservation/reservation.component';
import { AttractionMariannaComponent } from './attraction-marianna/attraction-marianna.component';
import { AttractionNeighbourhoodComponent } from './attraction-neighbourhood/attraction-neighbourhood.component';
import { MessageComponent } from './message/message.component';
import { InformationComponent } from './information/information.component';
import {ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatButtonModule, MatIconModule, MatInputModule, MatProgressSpinnerModule, MatDialog, MatDialogModule} from '@angular/material';
import { ApiService } from './services/api.service';
import { HttpClientModule } from '@angular/common/http';
import { DialogDoneComponent } from './dialog-done/dialog-done.component';
import { DialogErrorComponent } from './dialog-error/dialog-error.component';
import { MeetUsComponent } from './meet-us/meet-us.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { InProgressComponent } from './in-progress/in-progress.component';
import { EuInformationComponent } from './eu-information/eu-information.component';

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    NavbarComponent,
    FooterbarComponent,
    LogoComponent,
    RoomComponent,
    PriceListComponent,
    ReservationComponent,
    AttractionMariannaComponent,
    AttractionNeighbourhoodComponent,
    MessageComponent,
    InformationComponent,
    DialogDoneComponent,
    DialogErrorComponent,
    MeetUsComponent,
    NotFoundComponent,
    InProgressComponent,
    EuInformationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatDialogModule
  ],
  entryComponents: [
    DialogDoneComponent,
    DialogErrorComponent
  ],
  providers: [
    ApiService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
