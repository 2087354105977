import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainPageComponent} from './main-page/main-page.component';
import {RoomComponent} from './room/room.component';
import {PriceListComponent} from './price-list/price-list.component';
import {ReservationComponent} from './reservation/reservation.component';
import {AttractionMariannaComponent} from './attraction-marianna/attraction-marianna.component';
import {AttractionNeighbourhoodComponent} from './attraction-neighbourhood/attraction-neighbourhood.component';
import {MessageComponent} from './message/message.component';
import {InformationComponent} from './information/information.component';
import {MeetUsComponent} from './meet-us/meet-us.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {EuInformationComponent} from './eu-information/eu-information.component';

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    // pathMatch: 'full'
  },
  {
    path: 'pokoje',
    component: RoomComponent
  },
  {
    path: 'cennik',
    component: PriceListComponent
  },
  {
    path: 'rezerwacja',
    component: ReservationComponent
  },
  {
    path: 'atrakcje-marianna',
    component: AttractionMariannaComponent
  },
  {
    path: 'atrakcje-okolica',
    component: AttractionNeighbourhoodComponent
  },
  {
    path: 'poznaj-nas',
    component: MeetUsComponent
  },
  {
    path: 'napisz-do-nas',
    component: MessageComponent
  },
  {
    path: 'dojazd-kontakt',
    component: InformationComponent
  },
  {
    path: 'dofinansowanie-ue',
    component: EuInformationComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
