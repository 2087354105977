import { Component, OnInit } from '@angular/core';

declare var ol: any;

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {
  constructor() {
  }

  latitude = 51.3416;
  longitude = 21.8103;
  zoom = 10;

  // 51.3416, 21.8103
  map: any;

  ngOnInit() {
    this.map = new ol.Map({
      target: 'map',
      layers: [
        new ol.layer.Tile({
          source: new ol.source.OSM()
        }),
      ],
      view: new ol.View({
        center: ol.proj.fromLonLat([this.longitude, this.latitude]),
        zoom: this.zoom
      })
    });

    const vectorLayer = new ol.layer.Vector({
      source: new ol.source.Vector({
      features: [new ol.Feature({
        geometry: new ol.geom.Point(ol.proj.transform([this.longitude, this.latitude], 'EPSG:4326', 'EPSG:3857')),
      })]
    }),
      style: new ol.style.Style({
      image: new ol.style.Icon({
        className: 'icon-location',
        anchor: [0.5, 1],
        // anchorXUnits: "fraction",
        // anchorYUnits: "fraction",
        src: 'assets/icons/marker-icon.png',
        scale: 0.1
      })
    })
  });

    this.map.addLayer(vectorLayer);
  }
}
