import {Component, OnInit} from '@angular/core';
import {SeasonalGallery} from '../../utils/seasonal-gallery';
import {PhotoFrame} from '../../utils/photo-frame';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {

  private readonly pathToGallery = 'assets/main_page/';
  // gallery: SeasonalGallery[];
  winter: PhotoFrame[] = [
    {
      photoPath: this.pathToGallery + 'portrait1winter.jpg',
      description: 'Zimowa aura zawitała do siedliska. Zapraszamy na ferie 2024.'
    },
    {
      photoPath: this.pathToGallery + 'portrait2winter.jpg',
      // tslint:disable-next-line:max-line-length
      description: 'Znajdziesz u nas idealne warunki do pracy zdalnej. Sielska atmosfera sprzyja koncentracji i poprawia samopoczucie, nawet w bardzo pracowity dzień.'
    },
    {
      photoPath: this.pathToGallery + 'portrait3winter.jpg',
      description: 'Zimowy urlop urozmaicamy gorącą sauną, kąpielą w balii, a także relaksacyjnym masażem.'
    },
    {
      photoPath: this.pathToGallery + 'portrait4.jpg',
      description: 'Hej! Tu Gosia i Justyna, mama i córka, które goszczą Was w Siedlisku.'
    },
  ];

  summer: PhotoFrame[] = [
    {
      photoPath: this.pathToGallery + 'portrait1summer.jpg',
      description: 'Piękna pogoda zawitała do naszego ogrodu. Praktykujemy kąpiele słoneczne na tarasie.'
    },
    {
      photoPath: this.pathToGallery + 'portrait2summer.jpg',
      description: 'W warzywniaku rosną pyszne i zdrowe warzywa, które powędrują prosto na Wasz śniadaniowy stół.'
    },
    {
      photoPath: this.pathToGallery + 'portrait3summer.jpg',
      // tslint:disable-next-line:max-line-length
      description: 'Znajdziesz u nas idealne warunki do pracy zdalnej. Sielska atmosfera sprzyja koncentracji i poprawia samopoczucie, nawet w bardzo pracowity dzień.'
    },
    {
      photoPath: this.pathToGallery + 'portrait4.jpg',
      description: 'Hej! Tu Gosia i Justyna, mama i córka, które goszczą Was w Siedlisku.'
    },
  ];

  currentGallery: PhotoFrame[] = this.summer;

  ngOnInit(): void {
    // this.gallery = [
    //     {season: 'summer', content: this.summer},
    //     {season: 'winter', content: this.winter}
    //   ] as SeasonalGallery[];

  }

}
