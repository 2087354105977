import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {VisitorDto} from '../dtos/visitor-dto.model';
import {ApiService} from '../services/api.service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {DialogDoneComponent} from '../dialog-done/dialog-done.component';
import {DialogErrorComponent} from '../dialog-error/dialog-error.component';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {

  constructor(private api: ApiService, public dialog: MatDialog) {
  }

  visibleSpinner = false;
  name = new FormControl('', [Validators.required, Validators.pattern('^[a-zA-ZĄąĆćĘęŁłÓóŚśŻżŹź]{2,}\\s[a-zA-ZĄąĆćĘęŁłÓóŚśŻżŹź]{2,}')]);
  email = new FormControl('', [Validators.required, Validators.email]);
  phoneNumber = new FormControl('', [Validators.required, Validators.pattern('([0-9]{3}[-\\s]{0,1}[0-9]{3}[-\\s]{0,1}[0-9]{3})')]);
  message = new FormControl('');

  ngOnInit() {
  }

  getInvalidNameError() {
    if (this.name.hasError('required')) {
      return 'Pole obowiązkowe';
    }
    return this.name.hasError('pattern') ? 'Podana wartość jest nieprawidłowa' : '';
  }

  getInvalidEmailError() {
    if (this.email.hasError('required')) {
      return 'Pole obowiązkowe';
    }
    return this.email.hasError('email') ? 'Podana wartość niezgodna ze schematem adresu e-mail' : '';
  }

  getInvalidPhoneNumberError() {
    if (this.phoneNumber.hasError('required')) {
      return 'Pole obowiązkowe';
    }
    return this.phoneNumber.hasError('pattern') ? 'Podana wartość niezgodna ze schematem numeru telefonu' : '';
  }

  isDataValid() {
    return this.name.valid && this.email.valid && this.phoneNumber.valid;
  }

  sendMessage() {
    // if (this.isDataValid()) {
    //   this.visibleSpinner = true;
    //   let visitor: VisitorDto;
    //   visitor = {
    //     name: this.name.value,
    //     email: this.email.value,
    //     phoneNo: this.phoneNumber.value,
    //     message: this.message.value
    //   };
    //   this.api.sendMail(visitor)
    //     .subscribe(
    //       data => {
    //         this.visibleSpinner = false;
    //         this.dialog.open(DialogDoneComponent);
    //       },
    //       error => {
    //         this.visibleSpinner = false;
    //         this.dialog.open(DialogErrorComponent);
    //       }
    //     );
    // }
  }

}
